import React from 'react';

import './task.css';

const Task = ({content, date}: {content: string, date: any}) => {
    return <div className="task">
        {content}
    </div>
}

export default Task