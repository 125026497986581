import React from 'react';
import logo from './logo.svg';
import './app.css';
import Task from './components/task/task';


const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img alt="logo" className="App-logo" src={logo} />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Task content="Learn React" date="any" />
        </a>
      </header>
    </div>
  );
};

export default App;
